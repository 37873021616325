import React from "react";
import PropTypes from "prop-types";
import clsx from "clsx";

import { Row } from "react-bootstrap";
import SectionHeader from "components/SectionHeader";
import PortfolioItem from "components/PortfolioItem";
import PageSection from "components/PageSection";
import "./Others.scss";

const Others = ({ className, frontmatter, others }) => {
  if (!frontmatter) {
    return null;
  }

  const { anchor, header: rootHeader, subheader: rootSubHeader } = frontmatter;

  return (
    <PageSection className={clsx("others-section", className)} id={anchor}>
      <Row>
        <SectionHeader header={rootHeader} subheader={rootSubHeader} />
      </Row>
      <Row>
        {others.map(
          ({ content, puissance, tensions, benefits, year, lenght, width, height, price, delivery, mount, header, imageFileName, imageFileNameDetail, subheader }) => (
            <PortfolioItem
              key={header}
              imageFileName={imageFileName}
              header={header}
              subheader={subheader}
              content={content}
              imageFileNameDetail={imageFileNameDetail}
              extraInfo={
                <ul>
                    <li key={year}>Année: {year}</li>
                    <li key={lenght}>Longueur(mm): {lenght}</li>
                    <li key={width}>Largeur(mm): {width}</li>
                    <li key={height}>Hauteur(mm): {height}</li>
                    <li key={puissance}>Puissance(Watt): {puissance}</li>
                    <li key={tensions}>Tensions: {tensions}</li>
                    <li key={benefits}>Rendements: {benefits}</li>
                    <li key={price}>Tarif: {price}</li>
                    <li key={delivery}>Livraison: {delivery}</li>
                    <li key={mount}>Montage/démontage: {mount}</li>
                </ul>
              }
            />
          ),
        )}
      </Row>
    </PageSection>
  );
};

Others.propTypes = {
  className: PropTypes.string,
  frontmatter: PropTypes.object,
  others: PropTypes.array
};

Others.defaultProps = {
  className: null,
  frontmatter: null,
  others: null
};

export default Others;
