export default function reformatAllItems(items) {
  const {edges} = items;
  const itemsFormatted = [];
  edges.forEach(castle => {
    const {node} = castle;
    const {frontmatter} = node;
    itemsFormatted.push(frontmatter);
  })
  return itemsFormatted;
}
