import React from "react";
import PropTypes from "prop-types";

import CircleFAButton from "components/CircleFAButton";
import Image from "components/Image";
import "./ServiceItem.scss";
import Icon from "components/Icon";

const ServiceItem = ({ iconName, imageFileName, header, content }) => {
  let iconPart;
  if (iconName) {
    iconPart = <CircleFAButton iconName={iconName} />;
  }

  let imagePart;
  if (imageFileName) {
    imagePart = <Image className="service-item-image" fileName={imageFileName} />;
  }

  let contentPart;
  if (header.toLowerCase() === 'location') {
    contentPart= <p className="text-muted">
      {content}
      <br />
      <Icon size="2x" iconName="VisaIcon" />
      <Icon size="2x" iconName="MasterCardIcon" />
      <Icon size="2x" iconName="MoneyIcon" />
    </p>
  } else {
    contentPart= <p className="text-muted">
      {content}
    </p>
  }

  return (
    <>
      {iconPart}
      {imagePart}
      <h4 className="service-item-heading">{header}</h4>
      {contentPart}
    </>
  );
};

ServiceItem.propTypes = {
  iconName: PropTypes.string,
  imageFileName: PropTypes.string,
  header: PropTypes.string,
  content: PropTypes.string,
};

ServiceItem.defaultProps = {
  iconName: null,
  imageFileName: null,
  header: "",
  content: "",
};

export default ServiceItem;
