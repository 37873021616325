 import React from "react";
import PropTypes from "prop-types";
import clsx from "clsx";

import { Row } from "react-bootstrap";
import SectionHeader from "components/SectionHeader";
import PortfolioItem from "components/PortfolioItem";
import PageSection from "components/PageSection";
import "./Portfolio.scss";

const Portfolio = ({ className, frontmatter, castles }) => {
  if (!frontmatter) {
    return null;
  }

  const { anchor, header: rootHeader, subheader: rootSubHeader } = frontmatter;

  return (
    <PageSection className={clsx("portfolio-section", className)} id={anchor}>
      <Row>
        <SectionHeader header={rootHeader} subheader={rootSubHeader} />
      </Row>
      <Row>
        {castles.map(
          ({ content, norme, year, lenght, width, height, price, delivery, mount, header, imageFileName, imageFileNameDetails, subheader }) => (
            <PortfolioItem
              key={header}
              imageFileName={imageFileName}
              header={header}
              subheader={subheader}
              content={content}
              imageFileNameDetails={imageFileNameDetails}
              extraInfo={
                <ul>
                    <li key={norme}>Norme: {norme}</li>
                    <li key={year}>Année: {year}</li>
                    <li key={lenght}>Longueur(m): {lenght}</li>
                    <li key={width}>Largeur(m): {width}</li>
                    <li key={height}>Hauteur(m): {height}</li>
                    <li key={price}>Tarif: {price}</li>
                    <li key={delivery}>Livraison: {delivery}</li>
                    <li key={mount}>Montage/démontage: {mount}</li>
                </ul>
              }
            />
          ),
        )}
      </Row>
    </PageSection>
  );
};

Portfolio.propTypes = {
  className: PropTypes.string,
  frontmatter: PropTypes.object,
  castles: PropTypes.array
};

Portfolio.defaultProps = {
  className: null,
  frontmatter: null,
  castles: null
};

export default Portfolio;
