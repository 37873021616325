import React from "react";
import PropTypes from "prop-types";
import InstaLogo from "../../../content/assets/images/instagram-logo.png";

const Instagram = ({ name }) => (

  <a href={`https://www.instagram.com/${name}`}>
    <img
      alt="snapchat-logo"
      src={InstaLogo}
      width={40}
      height={40}
    />
  </a>
);

Instagram.propTypes = {
  name: PropTypes.string.isRequired,
};

export default Instagram;
