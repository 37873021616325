import React from "react";
import PropTypes from "prop-types";
import SnapChatLogo from "../../../content/assets/images/snapchat-logo.png";

const Snapchat = ({ userName }) => (
  <a href={`https://www.snapchat.com/add/${userName}`}>
    <img
      alt="snapchat-logo"
      src={SnapChatLogo}
      width={40}
      height={40}
    />
  </a>
);

Snapchat.propTypes = {
  userName: PropTypes.string.isRequired,
};

export default Snapchat;
