import React from "react";
import PropTypes from "prop-types";
import { graphql } from "gatsby";

import Navbar from "views/Navbar";
import Top from "views/Top";
import Footer from "views/Footer";
import * as Sections from "views/Sections";
import SEO from "components/SEO";

import "utils/fixFontAwesome";
import breakDownAllNodes from "utils/breakDownAllNodes";
import fileNameToSectionName from "utils/fileNameToSectionName";

import "../style/main.scss";
import reformatAllItems from "utils/reformatItems";

/**
 * get file name list from content/sections folder
 */
export const query = graphql`
  query IndexQuery($langKey: String!) {
    site {
      siteMetadata {
        keywords
        description
      }
    }
    castles: allMarkdownRemark(
          filter: {fields: {directoryName: {eq: "castles"}}}
          sort: {order: ASC, fields: [frontmatter___title]}
        ) {
          edges {
            node {
              fields {
                slug
              }
              frontmatter {
                title
                imageFileName
                imageFileNameDetails {
                 path
                }
                imageTest {
                  ucImageSrc
                }
                header
                subheader
                content
                norme
                year
                lenght
                width
                height
                price
                delivery
                mount
              }
            }
          }
        }
    others: allMarkdownRemark(
          filter: {fields: {directoryName: {eq: "others"}}}
          sort: {order: ASC, fields: [frontmatter___title]}
        ) {
          edges {
            node {
              fields {
                slug
              }
              frontmatter {
                title
                imageFileName
                imageFileNameDetails {
                  path
                }
                header
                subheader
                content
                year
                lenght
                width
                height
                benefit
                puissance
                tensions
                price
                delivery
                mount
              }
            }
          }
        }
    allMarkdownRemark(
      filter: { fields: { langKey: { eq: $langKey } } }
      sort: { order: ASC, fields: [fields___directoryName, fields___fileName] }
    ) {
      nodes {
        frontmatter {
          brand
          anchor
          content
          copyright
          header
          email
          imageFileName
          jumpToAnchor
          jumpToAnchorText
          menuText
          privacyHref
          privacyText
          services {
            content
            header
            iconName
            imageFileName
          }
          social {
            facebook
            github
            linkedin
            medium
            instagram
            twitter
          }
          subheader
          teamMember {
            header
            imageFileName
            social {
              facebook
              github
              linkedin
              instagram
              medium
              twitter
            }
            subheader
          }
          telephone
          termsHref
          termsText
          title
          timeline {
            content
            header
            imageContent
            imageFileName
            subheader
          }
        }
        fields {
          fileName
          directoryName
        }
      }
    }
  }
`;

const IndexPage = ({ data, pageContext: { langKey } }) => {
  const {
    site: {
      siteMetadata: { keywords, description },
    },
    castles,
    others,
    allMarkdownRemark: { nodes },
  } = data;

  const { topNode, navBarNode, anchors, footerNode, sectionsNodes } = breakDownAllNodes(nodes);
  const castlesFormatted = reformatAllItems(castles);
  const othersFormatted = reformatAllItems(others);
  return (
    <>
      <SEO lang={langKey} title="Top" keywords={keywords} description={description} />
      <Navbar
        anchors={anchors}
        frontmatter={navBarNode.frontmatter}
      />
      <Top frontmatter={topNode.frontmatter} />
      {
        // dynamically import sections
        sectionsNodes.map(({ frontmatter, fields: { fileName } }, ind) => {
          const sectionComponentName = fileNameToSectionName(fileName);
          const SectionComponent = Sections[sectionComponentName];

          return SectionComponent ? (
            <SectionComponent
              key={sectionComponentName}
              className={ind % 2 === 1 ? "bg-light" : null}
              frontmatter={frontmatter}
              castles={castlesFormatted}
              others={othersFormatted}
            />
          ) : null;
        })
      }
      <Footer frontmatter={footerNode.frontmatter} />
    </>
  );
};

IndexPage.propTypes = {
  data: PropTypes.object.isRequired,
  pageContext: PropTypes.object,
};

IndexPage.defaultProps = {
  pageContext: {
    langKey: "fr",
    defaultLang: "fr",
    langTextMap: {},
  },
};

export default IndexPage;
